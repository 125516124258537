/* You can add global styles to this file, and also import other style files */
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine-dark.css";

.ag-row-odd {
  background-color: #0d182a !important;
}
.ag-row-even {
  background-color: var(--main_bkg-color) !important;
}


.ag-floating-top{
   height: 87px !important;
   border: none !important;
   background-color: rgba(34,43,59,.6)!important;
}
.ag-row-pinned {
  background-color: rgba(34,43,59,.6)!important;
  border: none !important;

}

.ag-checkbox-input-wrapper {
  border-radius: none !important;
}
.ag-cell-focus{
  border-color: var(--main_color)!important;
}

.ag-cell-range-single-cell.ag-cell-range-handle{
  border-color: var(--main_color) !important;
}
.ag-checkbox-input{
  opacity: 100 !important;
  background-color: #353342 !important;
  border-radius: 2px !important;
  border: 1px solid #707070 !important;
}

.ag-icon-asc::before {
  color: var(--main_color);
}
.ag-icon-desc {
  color: var(--main_color);
}
.ag-cell-value {
  text-align: left;
}
.ag-root-wrapper {
  border: none !important;
  background-color: #081225 !important;
}
.ag-paging-panel {
  height: 55px !important;
  justify-content: flex-start !important;
  background-color: #0f1a2c !important;
}
.ag-header {
  background-color: var(--main_bkg-color) !important;
  border-left: none !important;
  border-right: none !important;
  border-top: 1px solid var(--main_gray_color) !important;
}

.ag-row {
  border-color: #465062 !important;
}
.ag-cell-range-single-cell {
  border-right-color: var(--main_gray_color) !important;
}
.ag-theme-alpine-dark
  .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
  border-right-color: var(--main_gray_color) !important;
}
.ag-theme-alpine-dark .ag-cell {
  // border-top-color: var(--main_gray_color) !important;
}
.ag-theme-alpine-dark {
  background-color: var(--main_bkg-color) !important;
}
:root {
  --main_color_transparent: 225, 153, 68;
  --bkg_color_light_gray: rgba(28, 38, 56, 1);
  --text_light_gray_color: rgba(66, 82, 109, 1);
  --dark_gray_color: #111b2d;
  --lightest_gray_color: #dddbe1;
  --light_gray_color: #7c808c;
  --main_color_active: #4eb900;
  --main_color: #e19944;
  --main_bkg-color: #081325;
  --main_bkg_color_sidebar: #242e40;
  --main_color_active: #4eb900;
  --white_color: #ffffff;
  --black_color: #000000;
  --main_gray_color: #475163;
  --main_gray_divider_color: #7f8191;
  --font_size_16px_1rem: 1rem;
  --dark-gradient-top-color: #192436;
  --dark-gradient-bottom-color: #293345;
  --dropdown-background-color: #0d182a;
  --dropdown-disabled-text-color: #42526d;

  --popups-background-color: #2c3648;

  --warning-color: #e85555;
  --success-color: #4eb900;

  --design-white-color: #dddbe1;

  --type-1-color: #b94e00;
  --type-2-color: #ddac00;
  --type-3-color: #008db9;
  --type-4-color: #4eb900;

  --recommendation-background-color: #1c2638;
}

@font-face {
  font-family: poppins;
  font-weight: 400;
  font-style: normal;
  src: url("./assets/fonts/Poppins-Regular.ttf") format("opentype");
}

@font-face {
  font-family: poppins;
  font-weight: 700;
  src: url("./assets/fonts/Poppins-SemiBold.ttf") format("opentype");
}

body {
  max-width: 100vw;
  margin: 0px;
}

* {
  box-sizing: border-box;
  font-family: "poppins";
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 8px 0;
}

p {
  margin: 0;
}

.pointer {
  cursor: pointer;
}

.btn {
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
}

.btn:disabled {
  pointer-events: none;
}

.flex {
  display: flex;
}
.column {
  display: flex;
  flex-direction: column;
}
.column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.center {
  display: flex;
  justify-content: center;
}
.center-all {
  display: flex;
  align-items: center;
  justify-content: center;
}
.space-between {
  display: flex;
  justify-content: space-between;
}
.space-around {
  display: flex;
  justify-content: space-around;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}
.wrap {
  display: flex;
  flex-wrap: wrap;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/////////// Landscape ///////////
@media screen and (min-aspect-ratio: 13/9) {
  #turn {
    display: flex;
    body {
      overflow: hidden;
    }
  }
}
@media (min-width: 1025px) {
  #custom-modal-7 {
    .jw-modal-body {
      height: 100%;
      margin: unset;
      border-radius: unset;
    }
  }
}
// modals
#custom-modal-7 {
  .jw-modal-body {
    margin: 10px;
    height: 95%;
    border-radius: 10px;
  }
}

.adNameTooltip {
  font-size: 14px !important;
  max-width: 700px !important;
  background: #0D182A !important;
  border: 1px solid #475163 !important;
  white-space: wrap !important;
  text-align: left !important;
  max-height: 100px;
}
.tooltip-right {
  &:after {
    border-color: #0000 #475163 #0000 #0000 !important;
  }
}

.tooltip-left {
  &:after {
    border-color: #0000 #0000 #0000 #475163 !important;
  }
}

.tooltip-top {
  &:after {
    border-color: #475163 #0000 #0000 #0000 !important;
  }
}

.tooltip-bottom {
  &:after {
    border-color: #0000 #0000 #475163 #0000 !important;
  }
}