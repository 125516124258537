.md-drppicker{
    border: 1px solid #475163 !important;
    table{
        border-spacing: 0px !important;
    }
    .calendar{
        margin-left:0 !important ;
        margin-right:0 !important ;
    }
    min-width: 540px!important;
    //height:400px !important;
    font-family: HelveticaNeue !important;
    background: #0D182A  !important;
    .calendar-table{
        background: #1C273A !important;
        border-color:#1C273A !important;
    }
    td.active, .md-drppicker td.active:hover{
        background: #E19944 !important;
        color: #0D182A !important;;
        box-shadow: 0px 0px 0px 0px #DFE9FB !important;
        height: 18px !important;
    }
    td.in-range{

        background: #39455A !important;
    }
    td.in-range.end-date{
        background: #E19944 !important;
        color:#000 !important;
    }

    td.off, .md-drppicker td.off.end-date, .md-drppicker td.off.in-range, .md-drppicker td.off.start-date{
        background: unset !important;
    }
    th.available:hover {
        background-color: unset !important;
    }
    th.available.next {
        color:#DFE9FB !important;
    }
    th{
        color:#fff !important;
        
        font-style: normal  !important;
        font-weight: 300  !important;
        font-size: 14px !important;
    }
    td.available:hover{
        background-color: #2C3648 !important;
        

    }
    td.in-range{
        color:#fff !important;
    }
    .btn{
        background: #2C3648 !important;
        color:#D5D4DF !important;
        font-family: Poppins !important;
        font-style: normal !important;
        font-weight: normal !important;
        padding: 0px 20px !important;
        border-radius: 10px !important;
    }
    th.available.next{
        background-image:url("assets/icons/left-arrow-white.svg") !important
    }

    th.available.prev{
        background-image:url("assets/icons/left-arrow-white.svg") !important
    }

    td.off.end-date.active{
        color:#999 !important;
    }

    // border-radius: 4px 4px 0px 0px  !important;
    // box-shadow: unset !important;
    .buttons_input{
        margin-right:-5px !important;
        margin-top: 10px !important;
        .btn.btn-default{
            background: transparent !important;
            color: #fff !important;
            box-shadow:unset !important;
        }
        .btn{
            
            background: #e19944 !important;
            color: #0D182A !important;
            font-family: Poppins  !important;
            font-style: normal !important;
            //font-weight: 600 !important;
            //font-size: 15px !important;

            //border: none !important;
            
        }
        
    }

     .ranges{
         width: 145px !important;
         
     }
    
    .ranges ul li button{
        color:#fff !important;
        //margin: 0 13px !important;
        width: 128px !important;
        border-radius: 16px !important;
    }
    // .ranges ul li button:active , .ranges ul li button:hover{
    //     background: transparent !important;
    // }
    // .ranges ul li button.hover{
    //     background: transparent !important;
    // }

    .ranges ul li:hover{
        background: unset !important;
    } 
    
   .ranges ul li button.active{
         background: #39455A !important;
         color:#e19944 !important;
    }

    td, th{
        height:30px !important;
        
    }

    td.end-date {
        border-radius: 0 20% 20% 0 !important ;
        
    }
    td.start-date {
        border-radius: 20% 0% 0% 20% !important ;
        
    }

    
    
    
}