



.plyr__control--overlaid{
  background-color: #FDA91E;
  opacity: 0.7;
}

.plyr--full-ui input[type=range]{
  color:#FDA91E;
  
}

.plyr--video .plyr__control:hover{
    background:#FDA91E;
  }